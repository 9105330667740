.module--image-with-text {
  --gap-desktop: 50px;
  --gap-mobile: 30px;
  display: flex;
  flex-direction: column;
  gap: var(--gap-desktop);
  padding-bottom: var(--gap-desktop);
}
@media (max-width: 991px) {
  .module--image-with-text {
    gap: var(--gap-mobile);
    padding-bottom: var(--gap-mobile);
  }
}
.module--image-with-text .image-with-text__inner {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 991px) {
  .module--image-with-text .image-with-text__inner {
    align-items: initial;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
.module--image-with-text .image-with-text__inner.image-with-text__inner--right {
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .module--image-with-text .image-with-text__inner.image-with-text__inner--right {
    flex-direction: column;
  }
}
.module--image-with-text .image-with-text__inner > * {
  flex: 1 1 50%;
  max-width: 50%;
}
@media (max-width: 991px) {
  .module--image-with-text .image-with-text__inner > * {
    flex: unset;
    max-width: unset;
  }
}
.module--image-with-text .image-with-text__inner.image-with-text__inner--small > .image-with-text__info-container {
  flex: 1 1 45%;
}
@media (max-width: 991px) {
  .module--image-with-text .image-with-text__inner.image-with-text__inner--small > .image-with-text__info-container {
    flex: unset;
  }
}
.module--image-with-text .image-with-text__inner.image-with-text__inner--small > .image-with-text__image-container, .module--image-with-text .image-with-text__inner.image-with-text__inner--small > .image-with-text__product-list {
  flex: 1 1 55%;
  max-width: 55%;
}
@media (max-width: 991px) {
  .module--image-with-text .image-with-text__inner.image-with-text__inner--small > .image-with-text__image-container, .module--image-with-text .image-with-text__inner.image-with-text__inner--small > .image-with-text__product-list {
    flex: unset;
    max-width: unset;
  }
}
.module--image-with-text .image-with-text__image-container {
  overflow: hidden;
  position: relative;
}
.module--image-with-text .image-with-text__image,
.module--image-with-text .html-5-video__video-container,
.module--image-with-text .vimeo-youtube__video-container {
  position: relative;
  padding-top: 74.15%;
}
@media (max-width: 1700px) {
  .module--image-with-text .image-with-text__image,
  .module--image-with-text .html-5-video__video-container,
  .module--image-with-text .vimeo-youtube__video-container {
    padding-top: 85%;
  }
}
@media (max-width: 1500px) {
  .module--image-with-text .image-with-text__image,
  .module--image-with-text .html-5-video__video-container,
  .module--image-with-text .vimeo-youtube__video-container {
    padding-top: 85%;
  }
}
@media (max-width: 1199px) {
  .module--image-with-text .image-with-text__image,
  .module--image-with-text .html-5-video__video-container,
  .module--image-with-text .vimeo-youtube__video-container {
    padding-top: 100%;
  }
}
@media (max-width: 991px) {
  .module--image-with-text .image-with-text__image,
  .module--image-with-text .html-5-video__video-container,
  .module--image-with-text .vimeo-youtube__video-container {
    padding-top: 75.35%;
  }
}
.module--image-with-text .image-with-text__image img,
.module--image-with-text .html-5-video__video-container img,
.module--image-with-text .vimeo-youtube__video-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: var(--object-position-desktop);
}
@media (max-width: 991px) {
  .module--image-with-text .image-with-text__image img,
  .module--image-with-text .html-5-video__video-container img,
  .module--image-with-text .vimeo-youtube__video-container img {
    object-position: var(--object-position-mobile);
  }
}
.module--image-with-text .image-with-text__info-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--background-color);
  color: var(--text-color);
  padding-left: 160px;
}
@media (max-width: 1500px) {
  .module--image-with-text .image-with-text__info-container {
    padding-left: min(11%, 160px);
  }
}
@media (max-width: 1199px) {
  .module--image-with-text .image-with-text__info-container {
    padding-left: 50px;
  }
}
@media (max-width: 991px) {
  .module--image-with-text .image-with-text__info-container {
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    order: 2;
  }
}
.module--image-with-text .image-with-text__inner--right .image-with-text__info-container {
  justify-content: flex-end;
  padding-right: 160px;
  padding-left: 0;
}
@media (max-width: 1500px) {
  .module--image-with-text .image-with-text__inner--right .image-with-text__info-container {
    padding-right: min(11%, 160px);
  }
}
@media (max-width: 1199px) {
  .module--image-with-text .image-with-text__inner--right .image-with-text__info-container {
    padding-right: 50px;
  }
}
@media (max-width: 991px) {
  .module--image-with-text .image-with-text__inner--right .image-with-text__info-container {
    padding: 20px;
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .module--image-with-text .container:not(.image-with-text__inner--space) .image-with-text__info-container {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 991px) {
  .module--image-with-text .image-with-text__inner--space .image-with-text__info-container {
    margin-top: 12px;
  }
}
.module--image-with-text .image-with-text__info-inner {
  display: flex;
  width: 100%;
  text-align: var(--text-position-desktop);
}
@media (max-width: 991px) {
  .module--image-with-text .image-with-text__info-inner {
    width: 100%;
    order: 2;
    text-align: var(--text-position-mobile);
  }
}
.module--image-with-text .image-with-text__inner--right .image-with-text__info-inner {
  justify-content: flex-end;
}
.module--image-with-text .image-with-text__info {
  --padding: 20px;
  padding: var(--padding);
  width: 100%;
  max-width: calc(413px + var(--padding) * 2);
}
@media (max-width: 991px) {
  .module--image-with-text .image-with-text__info {
    padding: unset;
    max-width: unset;
  }
}
.module--image-with-text .image-with-text__info-heading {
  font-family: "GT Super Ds", sans-serif;
  font-size: 40px;
  line-height: 1.25;
}
@media (max-width: 767px) {
  .module--image-with-text .image-with-text__info-heading {
    font-size: 25px;
    line-height: 1.3;
  }
}
.module--image-with-text .image-with-text__info-text {
  margin-top: 4px;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.01em;
}
@media (max-width: 767px) {
  .module--image-with-text .image-with-text__info-text {
    margin-top: 10px;
    font-size: 15px;
  }
}
.module--image-with-text .image-with-text__info-text.image-with-text__info-text--small {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}
@media (max-width: 767px) {
  .module--image-with-text .image-with-text__info-text.image-with-text__info-text--small {
    font-size: 13px;
    line-height: 1.33;
  }
}
.module--image-with-text .image-with-text__info-button {
  margin-top: 21px;
  text-decoration: unset;
  color: var(--button-text-color);
  background-color: var(--button-background-color);
  border: 1px solid var(--button-text-color);
  border-color: var(--button-text-color);
}
@media (max-width: 767px) {
  .module--image-with-text .image-with-text__info-button {
    min-width: 127px;
    padding-left: 17px;
    padding-right: 17px;
    line-height: 1.33;
    letter-spacing: 0.25em;
  }
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .module--image-with-text .image-with-text__info-button:not([disabled]):hover {
    color: var(--button-background-color);
    background-color: var(--button-text-color);
    border: 1px solid var(--button-background-color);
    border-color: var(--button-background-color);
    opacity: 0.9;
  }
}
.module--image-with-text .image-with-text__info-button:focus-visible {
  outline: 2px solid #2b2b2b;
}
.module--image-with-text .add-to-bag-block-link {
  background-color: #ffffff;
  width: 100%;
}
@media (max-width: 991px) {
  .module--image-with-text .add-to-bag-block-link {
    max-width: unset;
  }
}
.module--image-with-text .add-to-bag-block {
  bottom: 100%;
  z-index: 2;
}
@media (max-width: 1199px) {
  .module--image-with-text .add-to-bag-block {
    bottom: 0;
    z-index: 4;
  }
}
@media (min-width: 992px) {
  .module--image-with-text .image-with-text__inner--small.container {
    --padding-block: 160px;
    --content-width: 25%;
  }
}
@media (min-width: 992px) and (max-width: 1700px) {
  .module--image-with-text .image-with-text__inner--small.container {
    --content-width: 30%;
  }
}
@media (min-width: 992px) and (max-width: 1500px) {
  .module--image-with-text .image-with-text__inner--small.container {
    --content-width: 40%;
    --padding-block: 80px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .module--image-with-text .image-with-text__inner--small.container {
    --padding-block: 50px;
  }
}
@media (min-width: 992px) {
  .module--image-with-text .image-with-text__inner--small.container > .image-with-text__info-container {
    flex: 1 1 calc(var(--content-width) - var(--padding-block, 0));
    padding-right: var(--padding-block);
    padding-left: 0;
    justify-content: flex-start;
  }
  .module--image-with-text .image-with-text__inner--small.container > .image-with-text__image-container, .module--image-with-text .image-with-text__inner--small.container > .image-with-text__product-list {
    flex: 1 1 calc(100% - (var(--content-width) + var(--padding-block, 0)));
    max-width: calc(100% - (var(--content-width) + var(--padding-block, 0)));
  }
  .module--image-with-text .image-with-text__inner--small.container .image-with-text__info-inner {
    max-width: 413px;
  }
  .module--image-with-text .image-with-text__inner--small.container.image-with-text__inner--left > .image-with-text__info-container {
    padding-left: var(--padding-block);
    padding-right: 0;
  }
  .module--image-with-text .image-with-text__inner--small.container.image-with-text__inner--right > .image-with-text__info-container {
    justify-content: flex-end;
  }
}